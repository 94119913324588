<template>
  <div :class="uploadedImage ? 'md-layout-item image--uploaded' : 'md-layout-item'">
    <FilePond
      ref="filePond"
      label-idle="Drop your face here 🤳"
      @updatefiles="onInitUpload"
      @processfiles="onUpload"
      @addfile="onAdd"
      @removefile="onRemove"
      v-if="!getZipDirectory"
      :allow-process="false"
      :allow-image-resize="true"
      imageResizeTargetHeight="256"
      imageResizeTargetWidth="256"
      instant-upload="false"
      accepted-file-types="image/jpg, image/png, image/jpeg"
      maxFileSize="10MB"
      :disabled="uploading"
    />
    <p class="md-text-scrim">
      Please make sure the face is straight.
    </p>
    <md-checkbox v-model="accepted" class="pb-2">
      I have read and hereby accept <router-link :to="{path: '/terms'}">
      Fac3d's Terms & Conditions
    </router-link>
    </md-checkbox>
    <br/>
    <br/>
    <br/>
    <button class="raised-btn gradient-action-btn no-pulse"
      :disabled="!uploadedImage || !accepted" @click.prevent="onProcessFile">
      <slot v-if="uploading">Loading... <md-icon>flight_takeoff</md-icon></slot>
      <slot v-else>Generate 3D Model 🤞</slot>
    </button>
<!--    <canvas id="invisible-canvas" style="display:none;"></canvas>-->
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import vueFilePond from 'vue-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import EXIF from 'exif-js';
import imageCompression from 'browser-image-compression';

export default {
  name: 'FluffyUploader',
  components: {
    FilePond: vueFilePond(
      FilePondPluginImagePreview,
      FilePondPluginFileValidateSize,
      FilePondPluginFileEncode,
      FilePondPluginImageResize,
    ),
  },
  computed: {
    ...mapGetters(['getZipDirectory']),
    determineServer() {
      return process.env.NODE_ENV === 'development' ? this.localhost : `https://${process.env.VUE_APP_API_ENDPOINT}/infere`;
    },
  },

  data() {
    return {
      imageFile: null,
      uploadedImage: null,
      images: [],
      downloadFile: null,
      single: false,
      uploading: false,
      uploaded: false,
      localhost: 'http://127.0.0.1:5000/infere',
      accepted: false,
      needsRotating: false,
      options: {
        maxWidthOrHeight: 256,
      },
    };
  },

  methods: {
    onInitUpload(files) {
      this.uploadedImage = files[0].file;
      this.$store.dispatch('setImageForUser', this.uploadedImage);
      // FilePond instance methods are available on `this.$refs.filePond`
      // const pond = this.$refs.filePond;
      // this.images = pond.getFiles();
      this.exifOrientation(files[0].file);
    },
    onRemove() {
      this.uploadedImage = null;
      this.$store.dispatch('setImageForUser', null);
      this.needsRotating = false;
    },
    async onAdd() {
      const val = document.querySelector('.filepond--data > input').value;
      if (val) {
        const input = JSON.parse(val);
        this.imageFile = input.data;
      }
    },
    exifOrientation(file) {
      EXIF.getData(file, () => {
        const metadata = EXIF.getAllTags(file);
        const orientation = metadata.Orientation || null;
        if (orientation) {
          this.needsRotating = orientation === 6;
        }
      });
    },
    compressFile(file) {
      return new Promise((resolve, reject) => {
        imageCompression(file, this.options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    onProcessFile() {
      this.compressFile(this.uploadedImage).then((res) => {
        const file = new File([res], res.name);
        const formData = new FormData();
        this.uploadedImage = file;
        formData.append('filepond', file);
        formData.append('rotate', this.needsRotating);
        this.$store.dispatch('setImageForUser', this.uploadedImage);
        this.uploading = true;
        axios.post(`${process.env.VUE_APP_API_ENDPOINT}/infere`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          timeout: 90000,
        })
          .then(({ data }) => {
            const jsonData = data;
            this.uploading = false;
            if (jsonData === undefined || !jsonData) {
              this.$store.dispatch('setAlert', {
                show: true,
                title: 'Oops! Something wrong happened this time 😟',
                content: "It's our bad, please try again in a bit...",
              });
              this.$parent.$emit('cancelProcess');
            } else {
              this.uploading = false;
              this.$store.dispatch('storeJobId', jsonData.jobId);
              const temp = jsonData.fileName.split('.')[0];
              // this.imageFile = temp;
              this.$store.dispatch('storeUserFilename', temp);
              this.onUpload();
              // this.$parent.$emit('cancelProcess');
            }
          })
          .catch((err) => {
            this.uploading = false;
            if (err.status === 400) {
              this.$store.dispatch('setAlert', {
                show: true,
                title: 'An error occurred 😟',
                content: 'Please make sure you are uploading a valid image file!',
              });
              this.$parent.$emit('cancelProcess');
            } else {
              this.$store.dispatch('setAlert', {
                show: true,
                title: 'Oops! Something wrong happened this time 😟',
                content: "It's our bad, please try again in a bit...",
              });
              this.$parent.$emit('cancelProcess');
            }
          });
      });
    },
    onUpload() {
      this.uploaded = true;
      this.uploading = false;
      this.$emit('uploadCompleted');
    },
  },
  mounted() {
    this.$nextTick(() => {
      const target = document.querySelector('.filepond--credits');
      if (target) target.style.display = 'none';
    });
  },
};
</script>
<style scoped>
.md-checkbox {
  color: white;
}
.pb-2 {
  padding-bottom: 1rem !important;
}
</style>
